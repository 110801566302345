import { useAppSelector } from '@/state/hooks';
import { selectNotifications } from '@/state/reducers/notificationSlice';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import NotificationPreviewModal from '../modal/NotificationsModal/NotificationPreviewModal';
import { ListItemProps, NotificationAccent, NotificationStamp } from '../modules/Notifications';

interface NotificationsProps {
    onClick?: () => void;
}

export default function Notifications(props: NotificationsProps) {
    // const dispatch = useAppDispatch();
    const notifications = useAppSelector(selectNotifications);

    const screen = useScreenSizes();
    const disclosure = useDisclosure(false);
    // const [debouncedNotifications] = useDebouncedValue(notifications, 1000);

    const [selectedNotification, setSelectedNotification] = useState<any>(null);

    // useEffect(() => {
    //     dispatch(setNotificationsVisited(true));
    // }, [debouncedNotifications]);

    const VirtualizedNotification = ({ index, style }: ListItemProps) => {
        const notification = notifications[index];
        return (
            <div
                style={style}
                className="flex gap-3 bg-brand-background-dark text-neutral-200 p-2 border-b border-neutral-700"
                onClick={() => {
                    setSelectedNotification(notification);
                    disclosure[1].open();
                }}>
                <NotificationAccent notification={notification} />
                <div className="flex flex-col w-full gap-1 text-xs xs:text-sm">
                    <div className="flex w-full justify-between gap-2">
                        <div className="font-semibold line-clamp-2">{notification.title}</div>
                        <div className="text-neutral-400 whitespace-nowrap flex flex-col items-end">
                            <NotificationStamp notification={notification} />
                        </div>
                    </div>
                    <div className="text-neutral-400 line-clamp-2 text-xs sm:text-sm">{notification.body}</div>
                </div>
            </div>
        );
    };

    return (
        <div className="h-full w-full flex flex-col">
            <AutoSizer>
                {({ height, width }) => (
                    <FixedSizeList
                        height={height}
                        width={width}
                        itemCount={notifications.length}
                        itemSize={screen.mobile ? 90 : 100}>
                        {VirtualizedNotification}
                    </FixedSizeList>
                )}
            </AutoSizer>
            {notifications.length === 0 && (
                <div className="flex h-full items-center justify-center text-neutral-500">No notifications</div>
            )}
            {selectedNotification && (
                <NotificationPreviewModal opened={disclosure[0]} handlers={disclosure[1]} {...selectedNotification} />
            )}
        </div>
    );
}
