import {
    ServerEvent,
    ServerEventServiceClient,
    ServerEventServiceDefinition
} from '@/compiled_proto/com/celertech/baseserver/communication/protobuf/ServerEventService';
import { notificationDurationOptions } from '@/components/modal/SettingsModal/NotificationsTab';
import { forEachAsync } from '@/helpers/asyncIterableHelper';
import { logToServer } from '@/services/LogService';
import history from '@/utils/history';
import { parseNotificationSettings, toastError } from '@/utils/hooks/useToast';
import { Logger } from '@/utils/logger';
import { grpc } from '@improbable-eng/grpc-web';
import { Metadata, createChannel, createClient } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { User, controlClearSubscriptions, controlInitSubscriptions, doLogout } from '../reducers/authSlice';
import { AppDispatch, RootState } from '../store';

let subscriptionId: any = null;
let serverEventsSubscription: any = null;

const setupServerEventsSubscription = async (store: any, dispatch: AppDispatch, credentials: User) => {
    serverEventsSubscription = serverEventsClient.subscribeToServerEvents(
        {},
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    const currentSubscriptionId = uuidv4();
    subscriptionId = currentSubscriptionId;
    forEachAsync(
        serverEventsSubscription,
        (serverEvent: ServerEvent) => {
            const state: RootState = store.getState();
            const toastConfig = parseNotificationSettings({
                // id: 'serverEventDisconnected',
                id: 'force_logout',
                ...state.settings.notifications,
                duration: notificationDurationOptions[0]
            });
            Logger({
                title: 'Inbound: ServerEventsMiddleware event',
                callback: () => console.log({ serverEvent, subscriptionId, currentSubscriptionId })
            });

            if (
                !serverEvent.serviceConnected &&
                !['loggingIn', 'loggingOut', 'loggedOut'].includes(state.auth.loginStatus)
            ) {
                toastError({
                    body: 'You were disconnected from the backend service. Please login again.',
                    title: 'Service disconnected',
                    toastConfig
                });
                logToServer('error', `User ${credentials?.username} has been logged out due to service disconnection.`);
                dispatch(doLogout({ credentials }) as any);
                history.push('/login');
            }
        },
        () => subscriptionId !== currentSubscriptionId,
        `ServerEventsMiddleware`
    ).catch((error) => console.error({ 'ServerEventsMiddleware subscription': error }));
};

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

const serverEventsClient: ServerEventServiceClient = createClient(ServerEventServiceDefinition, wsChannel);

const ServerEventsMiddleware: Middleware = (store) => (next) => (action) => {
    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `ServerEventsMiddleware: Clear Subscriptions`, callback: () => {} });
        subscriptionId = null;
        serverEventsSubscription = null;
    } else if (controlInitSubscriptions.match(action)) {
        Logger({ title: `ServerEventsMiddleware: Initialise Subscriptions`, callback: () => {} });

        const dispatch: AppDispatch = store.dispatch;
        const state: RootState = store.getState();
        const creds = state.auth.user;

        // If not already listening, setup subscriptions
        if (!serverEventsSubscription && creds) {
            setupServerEventsSubscription(store, dispatch, creds);
        }
    }

    // Pass on to next middlewares in line
    next(action);
};

export default ServerEventsMiddleware;
