import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ResetUserPasswordToken } from './compiled_proto/com/celertech/baseserver/api/user/DownstreamAuthenticationUserProto';
import { Button } from './components/common/Button';
import { forgotPasswordSchema } from './components/form/schema/forgotPasswordSchema';
import RHFInput from './components/inputs/RHFInput';
import { logToServer } from './services/LogService';
import { sendResetPasswordEmail } from './services/UserService';
import useToast from './utils/hooks/useToast';

interface ForgotPasswordFormValues {
    username: string;
    server: unknown;
}

function ForgotPassword() {
    const navigate = useNavigate();
    const [toastError, toastSuccess] = useToast();

    const [retryTimer, setRetryTimer] = useState<number>(0);
    const [resetPasswordResponse, setResetPasswordResponse] = useState<ResetUserPasswordToken>();
    const form = useForm<ForgotPasswordFormValues>({
        defaultValues: {
            username: ''
        },
        mode: 'onChange',
        resolver: yupResolver(forgotPasswordSchema)
    });
    const { handleSubmit, setError, formState, register } = form;

    const onSubmit = async (data: ForgotPasswordFormValues) => {
        try {
            const user = await sendResetPasswordEmail(data.username);
            logToServer('info', `User [${data.username}] has requested password reset email.`);
            setResetPasswordResponse(user);
            setRetryTimer(30);
        } catch (rejectedValueOrSerializedError) {
            // handle error here
            const msg = 'BAD RESET PASSWORD';
            logToServer('info', `User [${data.username}] has trouble requesting password reset email.`);
            setError('server', { type: 'custom', message: msg });
            toastError({ body: msg, title: 'Reset password failure', persist: true });
        }
    };

    useEffect(() => {
        if (resetPasswordResponse) {
            const timer = setTimeout(() => setRetryTimer(retryTimer - 1), 1000);
            if (retryTimer < 1) {
                clearTimeout(timer);
                setResetPasswordResponse(undefined);
            }
        }
    }, [resetPasswordResponse, retryTimer]);

    return (
        <div className="h-full bg-brand-background flex flex-col justify-center py-12 px-6">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-8 w-auto" src={window.config?.logo} alt="GCEX" />
            </div>
            {resetPasswordResponse ? (
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="p-6 sm:p-8 rounded-md sm:rounded-lg sm:px-10 bg-brand-background-dark border-black shadow-[0_20px_76px_33px_rgba(0,0,0,0.66)]">
                        <div className="text-lg text-gray-200">
                            <p>Password reset email sent!</p>
                            <p>Please check your inbox for the reset link.</p>
                        </div>
                        <p className="text-sm text-gray-400">
                            Didn{"'"}t receive the email? Retry in {retryTimer}
                        </p>
                    </div>
                </div>
            ) : (
                <FormProvider {...form}>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="p-6 sm:p-8 rounded-md sm:rounded-lg sm:px-10 bg-brand-background-dark border-black shadow-[0_20px_76px_33px_rgba(0,0,0,0.66)]">
                            {formState.errors['server'] && formState.isSubmitted ? (
                                <span className="text-red-900 italic">{formState.errors['server'].message}</span>
                            ) : null}
                            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                <RHFInput {...register('username')} label="Username" autoComplete="username" />
                                <div>
                                    <Button isLoading={formState.isSubmitting}>Reset Password</Button>
                                </div>
                            </form>

                            <div
                                className="mt-6 relative flex justify-center text-sm font-medium text-neutral-300 hover:text-neutral-200 cursor-pointer"
                                onClick={() => navigate('/login')}>
                                Back to login page
                            </div>
                        </div>
                    </div>
                </FormProvider>
            )}
        </div>
    );
}

export default ForgotPassword;
