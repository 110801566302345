import { connectionConfig } from '@/config/chart';
import instrumentConfig from '@/config/instruments';
import { LibrarySymbolInfo, ResolutionString } from '@/types/charting_library';
import { Logger } from '@/utils/logger';
import BigNumber from 'bignumber.js';

const requests = {};
const NDConnection = new global.NetDania.JsApi.JSONConnection(connectionConfig);
let retriesOnCode128 = 0;

function getError(title, err) {
    switch (err.code) {
        case -128:
            Logger({
                title: `Inbound: ${title} data fetch error, instrument not available.`,
                callback: () => {
                    // console.log({ err });
                    // console.log('Resolving chart data with empty array.');
                    console.log({ err, request: requests[err.id]?.req });
                    console.log(`Retrying to fetch... retries: ${retriesOnCode128}`);
                }
            });
            requests[err.id]?.resolve([]);
            break;
        case -127:
            Logger({
                title: `Inbound: ${title} data fetch error, instrument not allowed`,
                callback: () => {
                    // console.log({ err });
                    // console.log('Resolving chart data with empty array.');
                    console.log({ err, request: requests[err.id]?.req });
                    console.log(`Resolving chart data with empty array.`, { request: requests[err.id] });
                    console.log(`Chart data fetching lifecylce have ended.`);
                }
            });
            requests[err.id]?.resolve([]);
            break;
        default:
            Logger({
                title: `Inbound: ${title} data fetch error - code: ${err.code}.`,
                callback: () => {
                    console.log({ err, request: requests[err.id]?.req });
                    console.log('Resolving chart data with empty array.');
                    console.log(`Unknown error received, please contact technical support.`);
                }
            });
            requests[err.id]?.resolve([], { noData: true });
            break;
    }
}

function getData(monitorChartResponse) {
    const TimeSeries = monitorChartResponse.get(global.NetDania.JsApi.Fields.CHART_TIME_STAMP);
    const OpenSeries = monitorChartResponse.get(global.NetDania.JsApi.Fields.CHART_OPEN);
    const HighSeries = monitorChartResponse.get(global.NetDania.JsApi.Fields.CHART_HIGH);
    const LowSeries = monitorChartResponse.get(global.NetDania.JsApi.Fields.CHART_LOW);
    const CloseSeries = monitorChartResponse.get(global.NetDania.JsApi.Fields.CHART_CLOSE);
    const VolumeSeries = monitorChartResponse.get(global.NetDania.JsApi.Fields.CHART_VOLUME);

    const ChartData = [
        TimeSeries,
        OpenSeries ? OpenSeries : CloseSeries,
        HighSeries ? HighSeries : CloseSeries,
        LowSeries ? LowSeries : CloseSeries,
        CloseSeries,
        VolumeSeries ? VolumeSeries : new Array(TimeSeries.length).fill(0)
    ];

    Logger({
        title: 'Inbound: Adding data to chart...',
        callback: () => {
            console.log(`Resolving chart data with ${ChartData[0].length} data points.`, {
                request: requests[monitorChartResponse.id]
            });
            console.log({ response: monitorChartResponse });
        }
    });

    // console.log({ response: monitorChartResponse });
    // const removeReq = global.NetDania.JsApi.Request.getReqObjRemove(monitorChartResponse.id);
    // NDConnection.AddRequest(removeReq);
    // NDConnection.Flush();

    if (requests[monitorChartResponse.id]) {
        requests[monitorChartResponse.id]?.resolve(ChartData);
        // delete requests[monitorChartResponse.id];
    }
}

NDConnection.addListener(global.NetDania.JsApi.Events.ONHISTORICALDATA, (res) => {
    Logger({
        title: 'Inbound: Historical Data',
        callback: () => console.log(res)
    });
    retriesOnCode128 = 0;
    getData(res);
});
NDConnection.addListener(global.NetDania.JsApi.Events.ONHISTORICALCHARTDATA, (res) => {
    Logger({
        title: 'Inbound: Extended Historical Data',
        callback: () => console.log(res)
    });
    retriesOnCode128 = 0;
    getData(res);
});
NDConnection.addListener(global.NetDania.JsApi.Events.ONERROR, (err) => {
    const originalRequest = requests[err.id]?.req;

    if (err.code === -128 && retriesOnCode128 < 2 && originalRequest) {
        retriesOnCode128 += 1;
        let points = originalRequest.pt;
        if (originalRequest.pt < 30) points = 30;

        const req = global.NetDania.JsApi.Request.getReqChartHistory(
            originalRequest.s,
            originalRequest.ts,
            points,
            'netdania_rtc',
            false,
            originalRequest.sd,
            originalRequest.ed
        );
        requests[req.i] = {
            req,
            resolve: requests[err.id].resolve
        };
        Logger({
            title: 'Outbound: Switch provider to netdania_rtc',
            callback: () =>
                console.log({
                    strSymbol: originalRequest.s,
                    intTimeScale: originalRequest.ts,
                    intPoints: originalRequest.pt,
                    strProvider: 'netdania_rtc',
                    boolMonitor: false,
                    historyFrom: originalRequest.sd,
                    to: originalRequest.ed
                })
        });
        NDConnection.AddRequest(req);
        NDConnection.Flush();
    } else if (err.code === -128 && retriesOnCode128 >= 2 && retriesOnCode128 < 5 && originalRequest) {
        retriesOnCode128 += 1;
        const reqExtended = global.NetDania.JsApi.Request.getReqChartHistoryExtended(
            originalRequest.s,
            originalRequest.ts,
            originalRequest.pt,
            'netdania_rtc',
            originalRequest.ed,
            false,
            false
        );
        requests[reqExtended.i] = {
            req: reqExtended,
            resolve: requests[err.id].resolve
        };
        Logger({
            title: 'Outbound: Extended fetch on netdania_rtc',
            callback: () =>
                console.log({
                    strSymbol: originalRequest.s,
                    intTimeScale: originalRequest.ts,
                    intPoints: originalRequest.pt,
                    strProvider: 'netdania_rtc',
                    fromTime: originalRequest.ed,
                    fromTimeInclusive: false,
                    futureDirection: false
                })
        });
        NDConnection.AddRequest(reqExtended);
        NDConnection.Flush();
    }
    getError('Subsequent', err);
    // delete requests[err.id];
});

const formatResults = (results) => {
    return results[0].map((result, i) => {
        return {
            time: results[0][i] * 1000,
            open: results[1][i],
            high: results[2][i],
            low: results[3][i],
            close: results[4][i],
            volume: results[5][i]
        };
    });
};

export const initDataFetch = async (symbol, interval, periodParams, provider, onHistoryCallback, onErrorCallback) => {
    const { from, to, countBack } = periodParams;

    const now = Math.floor(Date.now() / 1000);
    // For intervals smaller than a minute, fetch for the whole day.
    // Else, insufficient data on initial fetch will terminate the fetching process.
    const ticksFrom = interval < 60 && now - 86400;
    const ticksTo = interval < 60 && now;

    const promise1 = new Promise((resolve, reject) => {
        const req = global.NetDania.JsApi.Request.getReqChartHistory(
            symbol,
            interval,
            countBack,
            provider,
            false,
            ticksFrom || from,
            ticksTo || to
        );
        requests[req.i] = {
            req,
            resolve
        };
        Logger({
            title: 'Outbound: Initial chart data fetch',
            callback: () =>
                console.log({
                    strSymbol: symbol,
                    intTimeScale: interval,
                    intPoints: countBack,
                    strProvider: provider,
                    boolMonitor: false,
                    historyFrom: ticksFrom || from,
                    historyTo: ticksTo || to
                })
        });
        NDConnection.AddRequest(req);
        NDConnection.Connect();
        NDConnection.Flush();
    });

    const results: any = await promise1;
    if (results.length === 0 || !results) {
        onHistoryCallback([], { noData: true });
    } else {
        const formattedResults = formatResults(results);
        onHistoryCallback(formattedResults, { noData: false });
    }
};

export const subsequentDataFetch = async (
    symbol,
    interval,
    periodParams,
    provider,
    onHistoryCallback,
    onErrorCallback
) => {
    const { from, to, countBack, firstDataRequest } = periodParams;
    let points = countBack;
    if (countBack < 30) points = 30;

    // const historyFrom = from - minInterval(interval);

    const promise1 = new Promise((resolve, reject) => {
        const req = global.NetDania.JsApi.Request.getReqChartHistory(
            symbol,
            interval,
            points,
            provider,
            false,
            from,
            to
        );
        requests[req.i] = {
            req,
            resolve
        };

        Logger({
            title: 'Outbound: Subsequent chart data fetch',
            callback: () =>
                console.log({
                    strSymbol: symbol,
                    intTimeScale: interval,
                    intPoints: countBack,
                    strProvider: provider,
                    boolMonitor: false,
                    historyFrom: from,
                    historyTo: to
                })
        });
        NDConnection.AddRequest(req);
        NDConnection.Connect();
        NDConnection.Flush();
    });

    const results: any = await promise1;
    if (results.length === 0 || !results) {
        onHistoryCallback([], { noData: true });
    } else {
        const formattedResults = formatResults(results);
        onHistoryCallback(formattedResults, { noData: false });
    }
};

export const minInterval = (interval: number) => {
    if (interval === 604_800) return 2_592_000;
    if (interval === 2_592_000) return 31_536_000;
    else return 172_800;
    // if (interval === 1) return 3_600;
    // if (interval === 60) return 14_400;
    // else return 604_800;
    // else return 86_400;
};

export const convertResolutionToSeconds = (resolution: ResolutionString) => {
    switch (resolution) {
        case '1T':
            return 0;
        case '1S':
            return 1;
        case '1':
            return 60;
        case '15':
            return 900;
        case '60':
            return 3600;
        case '1H':
            return 3600;
        case '240':
            return 14400;
        case '4H':
            return 14400;
        case '720':
            return 43200;
        case '12H':
            return 43200;
        case '1D':
            return 86400;
        case '1W':
            return 604800;
        case '1M':
            return 2592000;
        default:
            return 86400;
    }
    // case '2S':
    //     return 2;
    // case '3S':
    //     return 3;
    // case '4S':
    //     return 4;
    // case '5S':
    //     return 5;
    // case '10S':
    //     return 10;
    // case '15S':
    //     return 15;
    // case '30S':
    //     return 30;
    // case '5':
    //     return 300;
};

export const constructSymbolInfo = (symbolItem, configurationData) => {
    const pip_size = instrumentConfig[symbolItem.full_name]?.pip_size || 1;
    return {
        ticker: symbolItem.full_name,
        name: symbolItem.symbol,
        description: symbolItem.description,
        type: symbolItem.type,
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: symbolItem.exchange,
        minmov: BigNumber(10).pow(-pip_size).toNumber(),
        pricescale: 100,
        visible_plots_set: 'ohlc',
        supported_resolutions: configurationData.supported_resolutions as any,
        volume_precision: 2,
        data_status: 'streaming',
        has_ticks: true,
        has_seconds: true,
        has_intraday: true,
        has_weekly_and_monthly: true,
        // '2', '3', '4', '5', '10', '15', '30'
        seconds_multipliers: ['1'],
        weekly_multipliers: ['1'],
        monthly_multipliers: ['1']
    } as LibrarySymbolInfo;
};
