import { logToServer } from '@/services/LogService';
import { Notification, pushNotification } from '@/state/reducers/notificationSlice';
import { store } from '@/state/store';
import { getAssetName } from '@/utils/symbolMapping';
import { Side } from '../compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { ExecutionType } from '../compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import { OrderType } from '../compiled_proto/com/celertech/orderrouting/api/enums/OrderTypeProto';
import { TimeInForceType } from '../compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { FxOrderSnapshotDownstreamEvent } from '../compiled_proto/com/celertech/orderrouting/api/order/DownstreamOrderProto';
import { BlotterItem } from '../model/blotters';
import { capitalize, capitalizeAll, getQtyFormatter } from '../utils/format';
import { OCOReference, titlePrice } from './orderStatusHelper';
// import { getOtherCurrency } from './currencyHelper';

export interface OrderEvent {
    orderId: string;
    price: number;
    qty: number;
}

export const toastExecutionType = (previousEvents: BlotterItem[], event: FxOrderSnapshotDownstreamEvent) => {
    const toast = getExecutionTypeToastConfig(previousEvents, event);
    if (toast) {
        store.dispatch(pushNotification(toast.notification as Notification));
        Promise.all([toast.logs]);
    }
};

export const getExecutionTypeToastConfig = (previousEvents: BlotterItem[], event: FxOrderSnapshotDownstreamEvent) => {
    const {
        // securityId,
        qty,
        price,
        stopPx,
        orderId,
        id,
        currency,
        // leg,
        securityCode,
        orderType,
        orderStatus,
        executionType,
        side: orderSide,
        timeInForce,
        orderMetadata
        // lastQty,
        // cumQty,
        // leavesQty
    } = event;

    const previousEvent = previousEvents.find((event) => event.orderId === orderId);

    const type = OrderType[orderType] as keyof typeof OrderType;
    const side = Side[orderSide] as keyof typeof Side;
    const oppSide = Side[orderSide === 1 ? 2 : 1] as keyof typeof Side;
    const execution_type = ExecutionType[executionType] as keyof typeof ExecutionType;

    const text = {
        orderType: `${capitalizeAll(type)} Order`,
        side: capitalize(side),
        oppSide: capitalize(oppSide),
        tif: TimeInForceType[timeInForce].toString(),
        titlePrice: titlePrice(
            orderType,
            previousEvent?.price || 0,
            previousEvent?.stopPrice || 0,
            orderMetadata.find((meta) => meta.key === 'SLIPPAGE')?.value || 0
        )
    };

    const strategy = orderMetadata.find(({ key }) => key === 'ORIGINAL_STRATEGY_ORDER_TYPE')?.value || '';
    const parentStrategy = orderMetadata.find(({ key }) => key === 'PARENT_STRATEGY_ID')?.value || '';
    const isPartOfOcoOrder = !!(strategy || parentStrategy);
    const formattedParentStrategy = parentStrategy.replace('STRATEGYORDER:PARENT:', '');

    const formatQty = getQtyFormatter();

    const fmtPrice = formatQty.format(price);
    const fmtQuantity = formatQty.format(qty);
    const fmtStopPrice = formatQty.format(stopPx);
    const fmtPrevQuantity = formatQty.format(previousEvent?.qty || 0);
    // const fmtLegPrice = formatQty.format(leg[0]?.price);
    // const fmtFilledAmount = formatQty.format(lastQty);
    // const fmtRemainingAmount = formatQty.format(leavesQty);
    // const fmtAccumulatedAmount = formatQty.format(cumQty);

    const ccy = getAssetName(currency);
    const [ccy1, ccy2] = securityCode.split('/');
    const ccy2Order = currency === ccy2;

    let position = '';
    if (ccy2Order) position = `${ccy2} vs ${ccy1}`;
    else position = `${ccy1} vs ${ccy2}`;

    const title = `${text.orderType} to ${text.side} ${fmtPrevQuantity} ${position} ${text.titlePrice}`;

    const showPrice = () => {
        switch (orderType) {
            case OrderType.LIMIT:
                return price !== previousEvent?.price && `Amended Price: ${fmtPrice}\n`;
            case OrderType.STOP_LIMIT:
                return stopPx !== previousEvent?.stopPrice && `Amended Price: ${fmtStopPrice}\n`;
            case OrderType.STOP_MARKET:
                return stopPx !== previousEvent?.stopPrice && `Amended Price: ${fmtStopPrice}\n`;
            default:
                return '';
        }
    };

    switch (execution_type) {
        case 'REPLACED': {
            const amendedPrice = showPrice();

            return {
                logs: logToServer('info', JSON.stringify({ message: `${title} Amended [${event.account}]`, event })),
                notification: {
                    type: 'success',
                    body: (
                        <div>
                            {amendedPrice && <p>{amendedPrice}</p>}
                            {qty !== previousEvent?.qty && (
                                <p>
                                    Amended Amount: {fmtQuantity} {ccy}
                                </p>
                            )}
                            <p>OrderId: {orderId}</p>
                            <p>Exec Id: {id}</p>

                            {isPartOfOcoOrder && <OCOReference formattedParentStrategy={formattedParentStrategy} />}
                        </div>
                    ),
                    title: `${title} Amended`,
                    created_at: new Date(),
                    isPartOfOcoOrder
                }
            };
        }
        default:
            break;
    }
};
