import { Notification } from '@/state/reducers/notificationSlice';
import { NotificationAccent, NotificationStamp } from '../../modules/Notifications';
import Modal, { ModalProps } from '../Modal';

type NotificationPreviewModal = ModalProps & Notification;

const NotificationPreviewModal = (props: NotificationPreviewModal) => {
    const { opened, handlers, ...notificationProps } = props;
    const { title, body } = notificationProps;

    return (
        <Modal opened={opened} handlers={handlers} className="h-auto" size="max-w-md" closeOnOverlay>
            <div className="flex gap-3 text-neutral-200 p-3 sm:p-4">
                <NotificationAccent notification={notificationProps} />
                <div className="flex flex-col w-full gap-1 text-sm sm:text-base">
                    <div className="flex flex-col xs:flex-row w-full justify-between gap-2">
                        <div className="font-semibold">{title}</div>
                        <div className="text-sm sm:text-base text-neutral-400 whitespace-nowrap flex flex-col items-start xs:items-end">
                            <NotificationStamp notification={notificationProps} />
                        </div>
                    </div>
                    <div className="text-neutral-400 whitespace-pre-wrap text-sm">{body}</div>
                </div>
            </div>
        </Modal>
    );
};

export default NotificationPreviewModal;
