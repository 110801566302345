import { TimeInForceType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import Divider from '@/components/common/Divider';
import FormBody from '@/components/form/components/FormBody';
import FormCurrency from '@/components/form/components/FormCurrency';
import FormFooter from '@/components/form/components/FormFooter';
import FormOrderTypeSwitch from '@/components/form/components/FormOrderTypeSwitch';
import FormSubmit from '@/components/form/components/FormSubmit';
import Totals from '@/components/form/components/Totals';
import { OrderForm, durationOptions, orderTypeOptions } from '@/components/form/OcoOrderForm';
import { OcoOrderFormInput, OcoOrderFormValues, ocoOrderSchema } from '@/components/form/schema/ocoOrderSchema';
import { getStopPrice } from '@/components/form/StopOrderForm';
import ExecutionInputController from '@/components/inputs/ExecutionInputController';
import Select from '@/components/inputs/Select';
import { submitOcoOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectSettings } from '@/state/reducers/settingSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import useOcoLimitOrder from '@/utils/hooks/useOcoLimitOrder';
import { invalidStopPriceMessage } from '@/utils/hooks/useOrderBook';
import { UseOrderExecutionTraderReturn } from '@/utils/hooks/useOrderExecutionTrader';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const defaultValues: Partial<OcoOrderFormInput> = {
    order1: {
        type: orderTypeOptions[2],
        limitPrice: undefined,
        quantity: null
    } as any,
    order2: {
        type: orderTypeOptions[0],
        stopPrice: undefined,
        stopLimitPrice: undefined,
        quantity: null
    } as any,
    duration: durationOptions[0]
};

interface OcoOrderFormProps extends UseOrderExecutionTraderReturn {
    onClose?: () => void;
    closeOnSubmit?: boolean;
}

function OcoOrderForm(props: OcoOrderFormProps) {
    const { onClose, closeOnSubmit = false, ...orderExecutionProps } = props;
    const {
        activeTicker,
        ccy1,
        ccy2,
        sideState,
        currencyState,
        orderBook,
        instrument,
        activeCurrencyConfig,
        isCcy2Order
    } = orderExecutionProps;

    const settings = useAppSelector(selectSettings);
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [side, setSide] = sideState;
    const [currency, setCurrency] = currencyState;

    const { formatPrice, pip_size, price_decimals, defaultSlippage } = instrument;
    const { asks, bids, bestAsk, bestBid, bestPrice, worstAsk, worstBid } = orderBook;
    const { min_order_size, order_decimals, increments } = activeCurrencyConfig;

    const form = useForm<OcoOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            ocoOrderSchema({
                priceOptions: {
                    side,
                    bestAsk,
                    bestBid,
                    formattedBestAsk: formatPrice(bestAsk),
                    formattedBestBid: formatPrice(bestBid),
                    ccy2Order: isCcy2Order
                },
                quantityOptions: {
                    min_order_size,
                    order_decimals,
                    currencyOut: currency,
                    currencyPair: activeTicker.show
                }
            })
        )
    });

    const {
        watch,
        reset,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = form;

    const [order1, order2] = watch(['order1', 'order2']);

    const onSubmit = async (data: OcoOrderFormInput, e) => {
        const dataValidated = data as OcoOrderFormValues;
        if (dataValidated.order1.quantity && dataValidated.order2.quantity && credentials) {
            if (closeOnSubmit) onClose?.();

            const slippage = dataValidated.order2.slippage
                ? BigNumber(dataValidated.order2.slippage)
                      .dividedBy(Math.pow(10, pip_size || 0))
                      .toString()
                : '0';

            const payload = {
                spotPrice: bestPrice,
                securityId: activeTicker.celer,
                currencyOut: currency,
                timeInForce: dataValidated.duration.value as TimeInForceType,
                order1: order1 as any,
                order2: { ...order2, slippage } as any,
                quantity: 0,
                side
            };
            await submitOcoOrder(payload, credentials, currentAccount);
        }
    };

    const onError = (errors) => console.error(errors);

    const { limitPriceTooltip, isRestingLimitOrder } = useOcoLimitOrder(form, orderExecutionProps, 'order1.');

    useEffect(() => {
        setValue('order2.stopPrice', getStopPrice(bestBid, bestAsk, side, isCcy2Order, price_decimals), {
            shouldValidate: true
        });
    }, [side]);

    useEffect(() => {
        const isCcy1Buy = side === Side.BUY && !isCcy2Order;
        const isCcy2Buy = side === Side.BUY && isCcy2Order;
        const isCcy1Sell = side === Side.SELL && !isCcy2Order;
        const isCcy2Sell = side === Side.SELL && isCcy2Order;

        if (order2?.stopPrice && bestBid && bestAsk) {
            if (isCcy1Buy || isCcy2Sell) {
                const message = invalidStopPriceMessage(side, formatPrice(bestBid), isCcy2Order);
                if (+order2.stopPrice <= bestBid) {
                    setError('order2.stopPrice', { type: 'stopPriceConstraint', message });
                }
            } else if (isCcy2Buy || isCcy1Sell) {
                const message = invalidStopPriceMessage(side, formatPrice(bestAsk), isCcy2Order);
                if (+order2.stopPrice >= bestAsk) {
                    setError('order2.stopPrice', { type: 'stopPriceConstraint', message });
                }
            } else {
                clearErrors('order2.stopPrice');
            }
        }
    }, [bestBid, bestAsk, isCcy2Order, side]);

    const resetDefault = useCallback(() => {
        // resets all fields, set limit price to default worst price
        let order1LimitPrice = worstAsk;
        if (side === Side.BUY && asks) order1LimitPrice = worstAsk;
        else if (side === Side.SELL && bids) order1LimitPrice = worstBid;

        const resetValues: typeof defaultValues = {
            ...defaultValues,
            order1: {
                type: orderTypeOptions[2],
                limitPrice: order1LimitPrice,
                quantity: null
            } as any,
            order2: {
                type: orderTypeOptions[0],
                stopPrice: getStopPrice(bestBid, bestAsk, side, isCcy2Order, price_decimals),
                stopLimitPrice: undefined,
                quantity: null,
                slippage: defaultSlippage
            } as any
        };

        if ([TimeInForceType.DAY, TimeInForceType.GTC].includes(settings.trading.defaultStopOrderDuration.value)) {
            resetValues.duration = settings.trading.defaultStopOrderDuration;
        }

        reset(resetValues);
    }, [
        defaultValues,
        side,
        isCcy2Order,
        price_decimals,
        bestAsk,
        bestBid,
        worstAsk,
        worstBid,
        settings,
        defaultSlippage
    ]);

    useDidUpdate(() => resetDefault(), [currency, side]);

    useEffect(() => {
        resetDefault();
    }, [activeTicker.celer]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="relative flex flex-col h-full w-full text-neutral-200">
                <FormBody side={side} mode="Modal">
                    <FormOrderTypeSwitch initialValue={side} onChange={setSide} />
                    <FormCurrency {...props} mode="Trader" currency={currency} setCurrency={setCurrency} />

                    <div className="flex flex-wrap gap-3">
                        <OrderForm
                            side={side}
                            title="Order 1"
                            idPrefix="order1"
                            pip_size={pip_size}
                            min_order_size={min_order_size}
                            increments={increments}
                            limitPriceTooltip={limitPriceTooltip}
                            isRestingLimitOrder={isRestingLimitOrder}
                        />
                        <OrderForm
                            side={side}
                            title="Order 2"
                            idPrefix="order2"
                            pip_size={pip_size}
                            min_order_size={min_order_size}
                            increments={increments}
                            defaultSlippage={defaultSlippage}
                        />
                    </div>
                    <ExecutionInputController name="duration" label="Duration *">
                        <Select type="execution" options={durationOptions} />
                    </ExecutionInputController>
                    <Totals
                        name="order1.quantity"
                        activePair={activeTicker}
                        side={side === Side.BUY ? Side.SELL : Side.BUY}
                        ccy1={ccy1}
                        ccy2={ccy2}
                        ccy2Order={isCcy2Order}
                        bids={bids}
                        asks={asks}
                        isTrader
                    />
                </FormBody>
                <Divider />
                <FormFooter mode="Modal">
                    <FormSubmit side={side} isValid={isValid} disabled={isSubmitting || !isValid} className="left-6" />
                </FormFooter>
            </form>
        </FormProvider>
    );
}

export default OcoOrderForm;
