import { invalidStopPriceMessage } from '@/utils/hooks/useOrderBook';
import { InferType, number, object, string } from 'yup';
import { Side } from '../../../compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { OrderType } from '../../../compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { Nullable } from '../../../model/common';
import { QuantitySchemaParams, quantitySchema } from './common';

interface ocoOrderSchemaParams {
    quantityOptions: QuantitySchemaParams;
    priceOptions: {
        side: Side;
        bestAsk: number;
        bestBid: number;
        formattedBestAsk: string;
        formattedBestBid: string;
        ccy2Order: boolean;
    };
}

export const ocoOrderSchema = ({ quantityOptions, priceOptions }: ocoOrderSchemaParams) => {
    return object({
        order1: object({
            type: object({ label: string(), value: number() }).required('Order 1 Type cannot be empty!'),
            limitPrice: number()
                .test('limitPriceRequired', 'Limit Price cannot be empty!', function (value) {
                    const { type } = this.parent;
                    if (type === OrderType.LIMIT) return !!value;
                    return true;
                })
                .typeError('Invalid Input: Please input a number.'),
            quantity: quantitySchema(quantityOptions)
        }),
        order2: object({
            type: object({ label: string(), value: number() }).required('Order 2 Type cannot be empty!'),
            stopPrice: stopPriceSchema(priceOptions),
            stopLimitPrice: stopLimitPriceSchema(),
            quantity: quantitySchema(quantityOptions),
            slippage: string()
        }),
        duration: object({ label: string(), value: number() }).required('Duration cannot be empty!')
    });
};

export type OcoOrderFormValues = InferType<ReturnType<typeof ocoOrderSchema>>;
export type OcoOrderFormInput = Nullable<OcoOrderFormValues>;

const stopPriceSchema = (priceOptions) => {
    const { side, bestAsk, bestBid, formattedBestAsk, formattedBestBid, ccy2Order } = priceOptions;

    const bestPrice = () => {
        switch (side) {
            case Side.BUY:
                return ccy2Order ? formattedBestAsk : formattedBestBid;
            case Side.SELL:
                return ccy2Order ? formattedBestBid : formattedBestAsk;
            default:
                return ccy2Order ? formattedBestBid : formattedBestAsk;
        }
    };

    return number()
        .test('stopPriceConstraint', invalidStopPriceMessage(side, bestPrice(), ccy2Order), function (price) {
            if (side === Side.BUY) {
                if (ccy2Order && +(price || 0) >= bestAsk) {
                    return false;
                } else if (!ccy2Order && +(price || 0) <= bestBid) {
                    return false;
                }
            }
            if (side === Side.SELL) {
                if (ccy2Order && +(price || 0) <= bestBid) {
                    return false;
                } else if (!ccy2Order && +(price || 0) >= bestAsk) {
                    return false;
                }
            }
            return true;
        })
        .test('stopPriceRequired', 'Stop Price cannot be empty!', function (value) {
            const { type } = this.parent;
            if (type === OrderType.STOP_MARKET || type === OrderType.STOP_LIMIT) return !!value;
            return true;
        })
        .typeError('Invalid Input: Please input a number.');
};

const stopLimitPriceSchema = () => {
    return number()
        .test('stopLimitPriceRequired', 'Stop Limit Price cannot be empty!', function (value) {
            const { type } = this.parent;
            if (type === OrderType.STOP_LIMIT) return !!value;
            return true;
        })
        .typeError('Invalid Input: Please input a number.');
};
